.seat {
    cursor: pointer;
    position: absolute;
    width: 1.3rem;
    height: 1.3rem;
    font-size: 0.6rem;
    font-weight: 700;
    background: #F7FAFC;
    border-color: #A0AEC0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: flex;
    border-width: 2px;
    border-radius: 9999px;
    border-style: solid;
}

.seat-disabled {
    background: #65B5CA;
    border-color: #65B5CA;
    color: black;
}

.seat-halfday {
    background: #FFFF66;
    border-color: #FFFF66;
    color: black;
}

.seat-halfday-morning {
    background: #FFFF66;
    border-color: #FFFF66;
}
.seat-halfday-afternoon {
    background: #fb923c;
    border-color: #fb923c;
}

.seat-selected {
    background: #B3E54B;
    border-color: #B3E54B;
}

.seat-highlighted {
    background: #B3E54B;
    border-color: #B3E54B;
    color: black;
}
