:root,
:host('.tab-selected') {
    --color-selected: red;
}

.welcome-card img {
    overflow: hidden;
}

@media screen and (min-width: 600px) {
    ion-content {
        max-width: 600px;
        margin: 0 auto !important;
    }

    .ion-page {
        align-items: center;
    }
}


.profile-img {
    text-align: center;
    margin: 0 auto;
    margin-top: 2rem;
    width: 100px;
    height: 100px;
    background-color: #f4f5f8;
    align-items: center;
    display: flex;
    align-content: center;

}

.profile-header {
    display: flex;
    align-content: center;
}

.mt-8 {
    margin-top: 2rem;
}

.content {
    padding: 0px 16px;
}

.error-message {
    background: #FED7D7;
    color: var(--ion-color-danger);
}

.initials {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0 auto;
}

.belegung-initials {
    background: #f4f5f8;
    display: flex;
    align-items: center;
}

.belegung-initials-content {
    margin: 0 auto;
    font-weight: bold;
}

.app-header {
    text-align: left;
    margin: 0;
    padding: 16px;
}

.seat-popover {
    position: absolute;
    background-color: white;
    margin-top: 25px;
    z-index: 10;
    padding: 8px;
}

.card-icon {
    position: absolute;
    right: 16px;
    width: 24px;
    height: 24px;
    top: 50%;
    transform: translateY(-50%);
}

.loader {
    text-align: center;
    margin: 0 auto;
}

.card-title {}

.card-title-seat {
    margin-top: 8px;
    display: block;
    font-size: 14px;
}

.sc-ion-label-md-s h2 {
    font-size: 14px;
}
.sc-ion-label-ios-s h2 {
    font-size: 14px;
}

.sc-ion-card-ios-h,
.button,
button,
.button-native,
:host {
    border-radius: 0 !important;
    border-width: 0;
}

.map-card ion-card-content {
    padding: 0;
}

.no-radius,
.custom-button {
    text-transform: none;
    border-radius: 0px !important;
    --border-top-left-radius: 0px;
    --border-top-right-radius: 0px;
    --border-bottom-left-radius: 0px;
    --border-bottom-right-radius: 0px;

    --border-radius:
        var(--border-top-left-radius) var(--border-top-right-radius) var(--border-bottom-right-radius) var(--border-bottom-left-radius);
}

.senn-header {
    margin: 24px 8px;
    display: flex;
    align-items: center;
    align-items: flex-end;
}

.mr-4 {
    margin-right: 1rem;
}

span[color="medium"] {
    color: #aaa;
}

@media screen and (max-width: 600px) {
    html.ios .senn-header {
        margin: 24px 16px;
    }

    #tab-button-tab1 .sc-ion-label-ios-s {
        font-size: 10px;
    }

    #tab-button-tab1 .senn-square {
        margin-top: 8px;
        margin-bottom: 6px;
    }
}

.senn-header.tab-2 {
    justify-content: start;
    width: 100%;
}

.senn-logo {
    margin-right: 1rem;
    margin-top: 2rem;
    height: 116px;
}

.uppercase {
    text-transform: uppercase;
}

.font-bold {
    font-weight: bold;
}


.senn-square {
    background: rgb(102, 102, 102);
    width: 16px;
    height: 16px;
    margin-bottom: 4px;
    margin-top: 4px;
}

.tab-selected .senn-square {
    background: black;
}

.cursor-pointer{
    cursor: pointer;
}